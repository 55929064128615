<!--
*  TTTech nerve-management-system
*  Copyright(c) 2022. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->
<template>
  <div
    id="iiotWorkloads"
    fill-height
    class="mr-4"
  >
    <the-nerve-table
      :id="'iiotWorkloadsTable'"
      :columns="columns"
      :params="params"
      :empty-state="$t('workloads.list.emptyList')"
      :is-action-menu-enabled="false"
      :is-row-clickable="canAccess('UI_WORKLOAD:VIEW')"
      store-module="workloads"
      @params-changed="updateParams"
      @row-clicked="params => rowClicked(params.item)"
    >
      <template v-slot:additional-actions>
        <v-row v-resize="onResize">
          <v-col
            id="iiotWorkloadsSelect"
            cols="5"
            lg="4"
          >
            <v-select
              ref="select"
              v-model="params.selectedType"
              :items="wlType"
              :item-text="wlType.text"
              :item-value="wlType.value"
              :placeholder="$t('workloads.list.workloadType')"
              prepend-icon="mdi-filter-outline"
              attach
              @change="filterBy()"
              id="iiotWorkloadsSelectInput"
            />
          </v-col>
          <v-col
            id="iiotWorkloadsShowDisabledCheckbox"
            class="pl-5 mt-2"
            cols="6"
            lg="4"
          >
            <v-checkbox
              v-model="params.disabled"
              class="checkbox-size"
              :label="$t('workloads.list.showDisabled')"
              @change="filterBy()"
            />
          </v-col>
        </v-row>
        <div
          v-if="canAccess('UI_WORKLOAD:CREATE')"
          :class="{'mb-2 justify-start': !isMarginVisible, 'pr-6 justify-end': isMarginVisible}"
          class="mt-0 d-inline-flex align-center"
        >
          <div
            v-for="type in wlTypeReversed"
            :key="type.value"
            class="{'pr-3': isMarginVisible, 'pl-3': !isMarginVisible}"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-if="type.value !== 'ALL'"
                  :id="btnId(type.value)"
                  depressed
                  x-large
                  dark
                  v-on="on"
                  class="workloadButtons"
                  @click="createWorkload(type.value)"
                >
                  <v-img
                    :src="`/img/addNew-${type.value}.png`"
                    class="workloadImages"
                  />
                </v-btn>
              </template>
              <span> {{ $t(`workloads.list.${btnTooltip(type.value)}`) }}</span>
            </v-tooltip>
          </div>
        </div>
      </template>
    </the-nerve-table>
  </div>
</template>

<script>
import { TheNerveTable } from 'nerve-ui-components';

export default {
  components: {
    TheNerveTable,
  },
  data() {
    return {
      wlType: [
        { value: 'ALL', text: this.$t('workloads.list.all') },
        { value: 'docker-compose', text: this.$t('workloads.list.compose') },
        { value: 'vm', text: this.$t('workloads.list.vm') },
        { value: 'docker', text: this.$t('workloads.list.docker') },
        { value: 'codesys', text: this.$t('workloads.list.codesys') },
      ],
      params: {
        selectedType: '',
        disabled: false,
      },
      isMarginVisible: true,
    };
  },
  props: {
    page: {
      type: Number,
    },
    itemsPerPage: {
      type: Number,
    },
    search: {
      type: String,
    },
    sortBy: {
      type: Array,
    },
    sortDesc: {
      type: Array,
    },
    type: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
  },
  created() {
    this.params = {
      page: this.page || 1,
      itemsPerPage: this.itemsPerPage || 10,
      search: this.search || '',
      sortBy: this.sortBy || [],
      sortDesc: this.sortDesc || [],
      selectedType: this.type || this.getQueryParams.selectedType || '',
      disabled: this.getQueryParams.disabled || this.disabled,
    };
  },
  computed: {
    columns() {
      if (this.canAccess('UI_WORKLOAD:DELETE') || this.canAccess('UI_WORKLOAD:DISABLE')) {
        return [
          {
            text: this.$t('workloads.list.headerName'),
            value: 'name',
            display: 'inline-flex',
            width: '30%',
            component: {
              sfc: () => import('@/components/workloads/helpers/WorkloadTableIcon.vue'),
              props: {
                isFirstCol: true,
              },
            },
          },
          {
            text: this.$t('workloads.list.headerType'),
            value: 'type',
            icon: 'typeIcon',
            color: 'color',
            width: '15%',
            component: {
              sfc: () => import('@/components/workloads/helpers/WorkloadTableIcon.vue'),
            },
            sortable: false,
          },
          {
            text: this.$t('workloads.list.headerCreated'),
            value: 'created',
            width: '50%',
          },
          {
            text: this.$t('defaultTable.action'),
            value: '',
            width: '0%',
            sortable: false,
            component: {
              sfc: () => import('@/components/workloads/helpers/WorkloadTableActions.vue'),
              props: {
                iconsAction: ['Delete', 'Disable', 'Enable'],
                fetchAction: () => this.filterBy,
              },
            },
          },
        ];
      }
      return [
        {
          text: this.$t('workloads.list.headerName'),
          value: 'name',
          display: 'inline-flex',
          width: '34%',
          component: {
            sfc: () => import('@/components/workloads/helpers/WorkloadTableIcon.vue'),
            props: {
              isFirstCol: true,
            },
          },
        },
        {
          text: this.$t('workloads.list.headerType'),
          value: 'type',
          icon: 'typeIcon',
          color: 'color',
          width: '34%',
          component: {
            sfc: () => import('@/components/workloads/helpers/WorkloadTableIcon.vue'),
          },
          sortable: false,
        },
        {
          text: this.$t('workloads.list.headerCreated'),
          value: 'created',
        },
      ];
    },
    getQueryParams() {
      return this.$store.getters['labels/getQuery'];
    },
    wlTypeReversed() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.wlType.reverse();
    },

  },
  methods: {
    updateParams(parameters) {
      this.params = {
        ...parameters,
        selectedType: this.params.selectedType,
        disabled: this.params.disabled,
      };
      this.$router.push({
        name: 'Workloads',
        query: {
          page: parameters.page,
          itemsPerPage: parameters.itemsPerPage,
          search: parameters.search || undefined,
          type: parameters.selectedType || undefined,
          sortBy: parameters.sortBy ? parameters.sortBy[0] : [],
          sortDesc: parameters.sortDesc ? parameters.sortDesc[0] : [],
          disabled: parameters.disabled,
        },
      }).catch(() => {});
    },
    async filterBy(action) {
      if (action === 'delete') {
        await this.$store.dispatch('workloads/fetch', this.params);
      } else {
        this.params.page = 1;
        await this.$store.dispatch('workloads/fetch', this.params);
      }
    },
    async rowClicked(item) {
      await this.$store.dispatch('labels/save_query', this.params);
      this.$router.push({ name: 'Add edit workload', params: { id: item._id, type: item.type } });
    },
    async createWorkload(type) {
      await this.$store.dispatch('labels/save_query', this.params);
      this.$router.push({ name: 'Add edit workload', params: { id: 'new', type } });
    },
    btnId(type) {
      if (type === 'vm') {
        return 'iiotWorkloadsAddVmWorkloadButton';
      }
      if (type === 'docker') {
        return 'iiotWorkloadsAddDockerWorkloadButton';
      }
      if (type === 'docker-compose') {
        return 'iiotWorkloadsAddComposeWorkloadButton';
      }
      return 'iiotWorkloadsAddCodesysWorkloadButton';
    },
    btnTooltip(type) {
      if (type === 'vm') {
        return 'addVm';
      }
      if (type === 'docker') {
        return 'addDocker';
      }
      if (type === 'docker-compose') {
        return 'addDockerCompose';
      }
      return 'addCodesys';
    },
    onResize() {
      this.isMarginVisible = window.innerWidth > 1264;
    },
  },
};
</script>

<style>
  #iiotWorkloadsSelectInput::placeholder{
    font-size: 14px !important;
  }
  .workloadButtons {
    background-color: transparent !important;
  }
  .workloadImages {
    width: 50px !important;
  }

</style>
